var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "subtitle-1 font-weight-bold mb-2" }, [
      _vm._v("Connecting to:"),
    ]),
    _c(
      "div",
      [
        _c("mew-select", {
          attrs: { items: _vm.ledgerApps, "is-custom": true },
          model: {
            value: _vm.ledgerApp,
            callback: function ($$v) {
              _vm.ledgerApp = $$v
            },
            expression: "ledgerApp",
          },
        }),
        _c(
          "div",
          { staticClass: "text-right" },
          [
            _c("access-wallet-derivation-path", {
              attrs: {
                "selected-path": _vm.selectedPath,
                "passed-paths": _vm.paths,
                "disable-custom-paths": true,
              },
              on: { setPath: _vm.setPath },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex flex-column align-center justify-center" },
          [
            _c(
              "div",
              { staticClass: "pb-8 pt-15 pt-md-18" },
              [
                _c("v-img", {
                  attrs: {
                    src: require("@/assets/images/icons/hardware-wallets/Ledger-Nano-X-image.svg"),
                    alt: "Ledger X Wallet",
                    "max-width": "21em",
                    "max-height": "10em",
                    contain: "",
                  },
                }),
              ],
              1
            ),
            !_vm.ledgerConnected
              ? _c(
                  "v-card-title",
                  { staticClass: "border justify-center font-wrapping" },
                  [
                    _c(
                      "div",
                      { staticClass: "mew-heading-4 font-weight-medium pl-1" },
                      [
                        _vm._v(
                          " Open the Ethereum App and Pair your Ledger Nano X "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.ledgerConnected
              ? _c(
                  "v-card-title",
                  { staticClass: "border justify-center font-wrapping" },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/icons/icon-checked.svg"),
                        alt: "Green check mark",
                        height: "20",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "mew-heading-4 font-weight-medium pl-1" },
                      [_vm._v(" Ledger Nano X connected ")]
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "d-flex justify-space-between justify-center text-center",
      },
      [
        _c("mew-button", {
          attrs: { "btn-size": "xlarge", title: "Connect via Bluetooth" },
          nativeOn: {
            click: function ($event) {
              return _vm.ledgerUnlockBle.apply(null, arguments)
            },
          },
        }),
        _c("mew-button", {
          attrs: { "btn-size": "xlarge", title: "Connect via USB" },
          nativeOn: {
            click: function ($event) {
              return _vm.ledgerUnlock.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }